(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('YieldCurveResultsController', YieldCurveResultsController);

    YieldCurveResultsController.$inject = ['YieldCurveResults'];

    function YieldCurveResultsController(YieldCurveResults) {

        var vm = this;

        vm.yieldCurveResults = [];

        loadAll();

        function loadAll() {
            YieldCurveResults.query(function(result) {
                vm.yieldCurveResults = result;
                vm.searchQuery = null;
            });
        }
    }
})();
