(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('paper-error', {
            parent: 'entity',
            url: '/paper-error',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'georgiaYieldCurvesApp.paperError.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paper-error/paper-errors.html',
                    controller: 'PaperErrorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paperError');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('paper-error-detail', {
            parent: 'paper-error',
            url: '/paper-error/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'georgiaYieldCurvesApp.paperError.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paper-error/paper-error-detail.html',
                    controller: 'PaperErrorDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paperError');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PaperError', function($stateParams, PaperError) {
                    return PaperError.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'paper-error',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('paper-error-detail.edit', {
            parent: 'paper-error-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-error/paper-error-dialog.html',
                    controller: 'PaperErrorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaperError', function(PaperError) {
                            return PaperError.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paper-error.new', {
            parent: 'paper-error',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-error/paper-error-dialog.html',
                    controller: 'PaperErrorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                paperId: null,
                                settleDate: null,
                                ytmDiff: null,
                                ytmObs: null,
                                ytmThe: null,
                                market: null,
                                residualMaturity: null,
                                weightYtm: null,
                                sigmaMarket: null,
                                sigmaOfftherun: null,
                                sigmaRm: null,
                                sigmaSpr: null,
                                sigmaVol: null,
                                description: null,
                                transDate: null,
                                volume: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('paper-error', null, { reload: 'paper-error' });
                }, function() {
                    $state.go('paper-error');
                });
            }]
        })
        .state('paper-error.edit', {
            parent: 'paper-error',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-error/paper-error-dialog.html',
                    controller: 'PaperErrorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaperError', function(PaperError) {
                            return PaperError.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paper-error', null, { reload: 'paper-error' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paper-error.delete', {
            parent: 'paper-error',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paper-error/paper-error-delete-dialog.html',
                    controller: 'PaperErrorDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaperError', function(PaperError) {
                            return PaperError.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paper-error', null, { reload: 'paper-error' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
