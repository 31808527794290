(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('epm-params', {
            parent: 'entity',
            url: '/epm-params',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'georgiaYieldCurvesApp.epmParams.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/epm-params/epm-params.html',
                    controller: 'EpmParamsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('epmParams');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('epm-params-detail', {
            parent: 'epm-params',
            url: '/epm-params/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'georgiaYieldCurvesApp.epmParams.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/epm-params/epm-params-detail.html',
                    controller: 'EpmParamsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('epmParams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'EpmParams', function($stateParams, EpmParams) {
                    return EpmParams.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'epm-params',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('epm-params-detail.edit', {
            parent: 'epm-params-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/epm-params/epm-params-dialog.html',
                    controller: 'EpmParamsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['EpmParams', function(EpmParams) {
                            return EpmParams.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('epm-params.new', {
            parent: 'epm-params',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/epm-params/epm-params-dialog.html',
                    controller: 'EpmParamsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                beta0: null,
                                beta1: null,
                                beta2: null,
                                beta3: null,
                                beta4: null,
                                lambda: null,
                                n: null,
                                m: null,
                                id: null,
                                gfit: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('epm-params', null, { reload: 'epm-params' });
                }, function() {
                    $state.go('epm-params');
                });
            }]
        })
        .state('epm-params.edit', {
            parent: 'epm-params',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/epm-params/epm-params-dialog.html',
                    controller: 'EpmParamsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['EpmParams', function(EpmParams) {
                            return EpmParams.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('epm-params', null, { reload: 'epm-params' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('epm-params.delete', {
            parent: 'epm-params',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/epm-params/epm-params-delete-dialog.html',
                    controller: 'EpmParamsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['EpmParams', function(EpmParams) {
                            return EpmParams.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('epm-params', null, { reload: 'epm-params' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
