(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('CurveLimitsDeleteController',CurveLimitsDeleteController);

    CurveLimitsDeleteController.$inject = ['$uibModalInstance', 'entity', 'CurveLimits'];

    function CurveLimitsDeleteController($uibModalInstance, entity, CurveLimits) {
        var vm = this;

        vm.curveLimits = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CurveLimits.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
