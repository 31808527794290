(function () {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('ext', {
                abstract: true,
                resolve: {
                    availableDates: ['YieldCurveResultsOpen', function (YieldCurveResultsOpen) {
                        return YieldCurveResultsOpen.query({datesOnly: ""}, function (res) {
                            return res.sort();
                        }).$promise;
                    }],
                    thresholdDate: ['AppDatesOpen', function (AppDatesOpen) {
                        return AppDatesOpen('old-curve-threshold').query().$promise;
                    }]
                }
            })
            .state('aaa', {
                parent: 'ext',
                abstract: true,
                resolve: {
                    availableDatesCorpAaa: ['CorporateCurveResultsOpen', function (CorporateCurveResultsOpen) {
                        return CorporateCurveResultsOpen.query({datesOnly: "", rating: "aaa"}, function (res) {
                            return res.sort();
                        }).$promise;
                    }]
                }
            })
            .state('bb', {
                parent: 'ext',
                abstract: true,
                resolve: {
                    availableDatesCorpBb: ['CorporateCurveResultsOpen', function (CorporateCurveResultsOpen) {
                        return CorporateCurveResultsOpen.query({datesOnly: "", rating: "bb"}, function (res) {
                            return res.sort();
                        }).$promise;
                    }]
                }
            })
            .state('ka', {
                parent: 'ext',
                url: '/ext/ka',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-ka.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('en', {
                parent: 'ext',
                url: '/ext/en',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-en.html',
                        controller: 'ExtController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('aaa-ka', {
                parent: 'aaa',
                url: '/ext/aaa/ka',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-aaa-ka.html',
                        controller: 'ExtAaaController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('aaa-en', {
                parent: 'aaa',
                url: '/ext/aaa/en',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-aaa-en.html',
                        controller: 'ExtAaaController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('bb-ka', {
                parent: 'bb',
                url: '/ext/bb/ka',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-bb-ka.html',
                        controller: 'ExtBbController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('bb-en', {
                parent: 'bb',
                url: '/ext/bb/en',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ext/ext-bb-en.html',
                        controller: 'ExtBbController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();
