(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .constant('chartConstants', {
            'lineColorSet': [
                '#32333E',
                '#42ADAC',
                '#A585FF',
                '#5891ED',
                '#F2915E',
                '#999EA2'
            ]
        });
})();
