(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('EpmParamsDetailController', EpmParamsDetailController);

    EpmParamsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'EpmParams'];

    function EpmParamsDetailController($scope, $rootScope, $stateParams, previousState, entity, EpmParams) {
        var vm = this;

        vm.epmParams = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('georgiaYieldCurvesApp:epmParamsUpdate', function(event, result) {
            vm.epmParams = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
