(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('PaperPriceController', PaperPriceController);

    PaperPriceController.$inject = ['PaperPrice'];

    function PaperPriceController(PaperPrice) {

        var vm = this;

        vm.paperPrices = [];

        loadAll();

        function loadAll() {
            PaperPrice.query(function(result) {
                vm.paperPrices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
