(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            dateformat: dateformat,
            convertDateToTsDate: convertDateToTsDate
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }

        /*converts Date object to the IRIS date string formatted according to the dateFormat*/
        function convertDateToTsDate(dateObj, frequency, dateFormat) {
            if (typeof(dateObj)=='string')
                dateObj = new Date(dateObj);
            var y = dateObj.getFullYear();
            var m = dateObj.getMonth();
            var p = ((frequency == 1) ? 1 : (frequency == 4) ? Math.ceil((m + 1) / 3) : m + 1).toString();
            var freqLetter = (frequency == 1) ? 'y' : (frequency == 4) ? 'q' : 'm';
            var dateString = dateFormat;
            dateString = dateString.replace(/yyyy/i, y.toString());
            dateString = dateString.replace(/yy/i, y.toString().slice(-2));
            dateString = dateString.replace(/f/, freqLetter);
            dateString = dateString.replace(/F/, freqLetter.toUpperCase());
            dateString = dateString.replace(/pp/i, p.length == 1 ? '0' + p : p);
            dateString = dateString.replace(/p/i, p);
            // if (frequency == 12) {
            var monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"];
            dateString = dateString.replace(/Mmmm/, monthNames[m]);
            dateString = dateString.replace(/mmmm/, monthNames[m].toLowerCase());
            dateString = dateString.replace(/MMMM/, monthNames[m].toUpperCase());
            dateString = dateString.replace(/Mmm/, monthNames[m].slice(0,3));
            dateString = dateString.replace(/mmm/, monthNames[m].toLowerCase().slice(0,3));
            dateString = dateString.replace(/MMM/, monthNames[m].toUpperCase().slice(0,3));
            dateString = dateString.replace(/mm/i, (m<=8)?'0'+(m+1).toString():(m+1).toString());
            dateString = dateString.replace(/m/i, (m+1).toString());
            // }

            return dateString;
        }
    }

})();
