(function () {
    'use strict';
    angular
        .module('georgiaYieldCurvesApp')
        .factory('AppDatesOpen', AppDatesOpen);

    AppDatesOpen.$inject = ['$resource', 'DateUtils'];

    function AppDatesOpen($resource, DateUtils) {
        var resourceUrl = 'api/open/app-dates/';

        return function (dateType) {
            return $resource(resourceUrl + dateType, {}, {
                'query': {method: 'GET', isArray: true}
            });
        };
    }
})();
