(function () {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .provider('chartConfig', chartConfig);

    // configuration
    chartConfig.$inject = ['chartConstants'];
    function chartConfig(chartConstants) {
        this.yieldCurveChartDefaultObject = {
            // title: {
            //     //Title text is to be filled in the app
            //     text: '',
            //     //Configure chart title font
            //     font:{
            //         opacity: 1,
            //         fontFamily: "Open Sans",
            //         fontWeight: 'lighter',
            //         color: "#337AB6",
            //         size: '16px'
            //     }
            // },
            //Configure Primary X Axis
            primaryXAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#777"
                },
                visible: true, // to be changed for empty charts
                title: {visible: false, text: 'Tenor'},
                axisLine: {visible: true},
                majorGridLines: {visible: true},
                majorTickLines: {visible: true},
                rangePadding: 'none',
                valueType: 'double',
                labelPlacement: 'onticks',
                labelIntersectAction: 'multipleRows',
                isIndexed: false,
                range: {min: 0, interval: 365}
            },
            //Configure Primary Y Axis
            primaryYAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#777"
                },
                title: {
                    visible: true,
                    text: 'Yield (%, annually compounded)',
                    font: {
                        size: "13px",
                        fontFamily: "Helvetica",
                        color: "#777"
                    }
                },
                visible: true, // to be changed for empty charts
                axisLine: {visible: true},
                opacity: 0.5,
                majorGridLines: {visible: true}//, rangePadding: 'additional'
            },
            //Configure Common Properties for all the series
            commonSeriesOptions: {
                enableAnimation: true,
                opacity: 0.75,
                border: {
                    width: 2
                },
                marker: {
                    border: {
                        width: 0
                    }
                },
                highlightSettings: {
                    enable: true,
                    mode: 'series',
                    opacity: 1
                }
            },
            //Series are to be filled in the app
            series: [],
            //Configure Zooming
            zooming: {
                enable: false,
                enableMouseWheel: false,
                enableScrollbar: true,
                type: 'x,y'
            },
            //Configure Trackball
            crosshair: {
                visible: true,
                type: 'trackball'
            },
            //OnLoad event handler
            // load: onChartLoaded,
            //Chart appearance and other properties
            chartArea: {
                border: {
                    opacity: 1
                }
            },
            border: {
                width: 0,
                color: "#5250A1"
            },
            canResize: true,
            isResponsive: true,
            legend: {
                visible: true,
                shape: 'seriesType',
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#777"
                }
            },
            size: {
                height: '250'//,width: '300'
            },
            annotations: [{
                visible: false, // to be changed for empty charts
                content: "watermark",
                opacity: 0.2,
                region: "series"
            }],
            //customize the trackball tooltip
            // tooltip: {visible: true, format: " #point.text# : #point.y#  "},
            trackToolTip: onTooltipShown,
            axesLabelRendering: onAxisLabelRender
        };

        this.yieldCurveChartDefaultLineStyles = {
            predefined: [ //todo: only six lines are predefined so far, will see if more is needed
                {
                    width: 3,
                    color: chartConstants.lineColorSet[0],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[1],
                    dashSetting: [1, 0],
                    markerShape: 'Diamond',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[2],
                    dashSetting: [1, 0],
                    markerShape: 'InvertedTriangle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[3],
                    dashSetting: [1, 0],
                    markerShape: 'Star',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[4],
                    dashSetting: [1, 0],
                    markerShape: 'Triangle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[5],
                    dashSetting: [1, 0],
                    markerShape: 'Pentagon',
                    markerSize: 2
                }
            ],
            new: {
                width: 3,
                color: null, //will be picked automatically
                dashSetting: [1, 0],
                markerShape: 'None',
                markerSize: 0
            }
        };

        this.xTicksTranslations = {
            "today": {en: "today", ka: "დღეს"},
            "1 day": {en: "1 day", ka: "1 დღე"},
            "1 week": {en: "1 week", ka: "1 კვირის"},
            "2 weeks": {en: "2 weeks", ka: "2 კვირა"},
            "3 weeks": {en: "3 weeks", ka: "3 კვირა"},
            "1 month": {en: "1 month", ka: "1 თვე"},
            "2 months": {en: "2 months", ka: "2 თვე"},
            "1 quarter": {en: "1 quarter", ka: "1 კვარტალი"},
            "2 quarters": {en: "2 quarters", ka: "2 კვარტალი"},
            "3 quarters": {en: "3 quarters", ka: "3 კვარტალი"},
            "1 year": {en: "1 year", ka: "1 წელი"},
            "5 quarters": {en: "5 quarters", ka: "5 კვარტალი"},
            "6 quarters": {en: "6 quarters", ka: "6 კვარტალი"},
            "7 quarters": {en: "7 quarters", ka: "7 კვარტალი"},
            "2 years": {en: "2 years", ka: "2 წელი"},
            "3 years": {en: "3 years", ka: "3 წელი"},
            "4 years": {en: "4 years", ka: "4 წელი"},
            "5 years": {en: "5 years", ka: "5 წელი"},
            "6 years": {en: "6 years", ka: "6 წელი"},
            "7 years": {en: "7 years", ka: "7 წელი"},
            "8 years": {en: "8 years", ka: "8 წელი"},
            "9 years": {en: "9 years", ka: "9 წელი"},
            "10 years": {en: "10 years", ka: "10 წელი"},
            "11 years": {en: "11 years", ka: "11 წელი"},
            "12 years": {en: "12 years", ka: "12 წელი"},
            "13 years": {en: "13 years", ka: "13 წელი"},
            "14 years": {en: "14 years", ka: "14 წელი"},
            "15 years": {en: "15 years", ka: "15 წელი"},
            "16 years": {en: "16 years", ka: "16 წელი"},
            "17 years": {en: "17 years", ka: "17 წელი"},
            "18 years": {en: "18 years", ka: "18 წელი"},
            "19 years": {en: "19 years", ka: "19 წელი"},
            "20 years": {en: "20 years", ka: "20 წელი"},
            "21 years": {en: "21 years", ka: "21 წელი"},
            "22 years": {en: "22 years", ka: "22 წელი"},
            "23 years": {en: "23 years", ka: "23 წელი"},
            "24 years": {en: "24 years", ka: "24 წელი"},
            "25 years": {en: "25 years", ka: "25 წელი"},
            "26 years": {en: "26 years", ka: "26 წელი"},
            "27 years": {en: "27 years", ka: "27 წელი"},
            "28 years": {en: "28 years", ka: "28 წელი"},
            "29 years": {en: "29 years", ka: "29 წელი"},
            "30 years": {en: "30 years", ka: "30 წელი"}
        };

        this.$get = function () {
            return {
                yieldCurveChartDefaults: {
                    chartObject: this.yieldCurveChartDefaultObject,
                    lineStyles: this.yieldCurveChartDefaultLineStyles,
                    xTicksTranslations: this.xTicksTranslations
                }
            };
        };

        function onTooltipShown(sender) {
            var dataPoint = sender.data.Series.dataPoint[sender.data.pointIndex];
            //reformat Y number up to 4th precision point
            sender.data.currentText = dataPoint.text + " : " + dataPoint.y.toPrecision(4);
        }

        function onAxisLabelRender(sender) {
            // format horizontal axis
            if (sender.data.axis.orientation === 'horizontal') {
                var actInterval = angular.copy(sender.data.axis.actualRange.interval);
                actInterval = (actInterval >= 365) ? 365 : 1;
                var freqLetter = (actInterval === 365) ? "Y" : "D";
                sender.data.label.Text = (sender.data.label.Text == "0")
                    ? "" // show "" for the zero tenor
                    : parseFloat(sender.data.label.Text) / actInterval + freqLetter;
            }
        }
    }
})();
