(function () {
    'use strict';
    angular
        .module('georgiaYieldCurvesApp')
        .factory('CurveLimitsOpen', CurveLimitsOpen);

    CurveLimitsOpen.$inject = ['$resource', 'DateUtils'];

    function CurveLimitsOpen($resource, DateUtils) {
        var resourceUrl = 'api/open/curve-limits/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validFrom = DateUtils.convertLocalDateFromServer(data.validFrom);
                        data.validTo = DateUtils.convertLocalDateFromServer(data.validTo);
                    }
                    return data;
                }
            }
        });
    }
})();
