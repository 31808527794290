(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('CurveLimitsController', CurveLimitsController);

    CurveLimitsController.$inject = ['CurveLimits'];

    function CurveLimitsController(CurveLimits) {

        var vm = this;

        vm.curveLimits = [];

        loadAll();

        function loadAll() {
            CurveLimits.query(function(result) {
                vm.curveLimits = result;
                vm.searchQuery = null;
            });
        }
    }
})();
