(function() {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('FileStoreDialogController', FileStoreDialogController);

    FileStoreDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'FileStore', 'YieldCurveResults'];

    function FileStoreDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, FileStore, YieldCurveResults) {
        var vm = this;

        vm.fileStore = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.yieldcurveresults = YieldCurveResults.query({filter: 'filestore-is-null'});
        $q.all([vm.fileStore.$promise, vm.yieldcurveresults.$promise]).then(function() {
            if (!vm.fileStore.yieldCurveResultsId) {
                return $q.reject();
            }
            return YieldCurveResults.get({id : vm.fileStore.yieldCurveResultsId}).$promise;
        }).then(function(yieldCurveResults) {
            vm.yieldcurveresults.push(yieldCurveResults);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.fileStore.id !== null) {
                FileStore.update(vm.fileStore, onSaveSuccess, onSaveError);
            } else {
                FileStore.save(vm.fileStore, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('georgiaYieldCurvesApp:fileStoreUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
