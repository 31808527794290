(function () {
    'use strict';

    angular
        .module('georgiaYieldCurvesApp')
        .controller('ExtBbController', ExtBbController);

    ExtBbController.$inject = ['$scope', '$rootScope', '$q', '$state', 'Principal', 'LoginService', 'DateUtils',
        'DataUtils', 'availableDatesCorpBb', 'CorporateCurveResultsOpen', 'ParCorporateCurvePointOpen', 'CorporateCurvePointOpen',
        'DataExportOpen'];

    function ExtBbController($scope, $rootScope, $q, $state, Principal, LoginService, DateUtils, DataUtils, availableDatesCorpBb,
        CorporateCurveResultsOpen, ParCorporateCurvePointOpen, CorporateCurvePointOpen, DataExportOpen) {
        var vm = this;
        vm.langKey = $state.current.name.slice(-2);
        moment.locale(vm.langKey);
        vm.availableDates = availableDatesCorpBb.map(function (ad) {
            return moment(ad);
        });
        //get the latest available date if any
        vm.lastAvailable = null;
        if (vm.availableDates && vm.availableDates.length > 0) {
            vm.lastAvailable = vm.availableDates[vm.availableDates.length - 1];
        }
        //pre-select the latest available date on page load
        vm.selectedDates = [vm.lastAvailable];
        //switch to the month of the latest available date
        vm.activeMonth = vm.lastAvailable;
        //define default data set for the chart
        vm.chartData = {
            chartId: "yc_chart",
            lineSeries: {par: [], zero: []},
            lineLegends: []
        };
        vm.isSplineInterpolated = true;
        vm.curveType = "zero";
        vm.curveLimits = [];
        vm.chartMinTenor = null;
        vm.chartMaxTenor = null;
        vm.minSlider = 2;
        vm.maxSlider = 30;
        vm.sliderStep = 1;
        vm.curveRangeLimit = null;
        vm.curveRangeModel = null;
        vm.sliderTitle = (vm.langKey === 'ka')
            ? 'წლები'
            : 'years';
        //list all the methods
        vm.clearDates = clearDates;
        vm.getResultsForDate = getResultsForDate;
        vm.onCurveRangeChanged = onCurveRangeChanged;
        vm.onCurveRangeSaved = onCurveRangeSaved;
        vm.onDaySelected = onDaySelected;
        vm.onExcelExportClicked = onExcelExportClicked;
        vm.parCurveSelected = parCurveSelected;
        vm.updateAxisTitle = updateAxisTitle;
        vm.zeroCurveSelected = zeroCurveSelected;
        //add latest available curve to the chart (if any)
        if (vm.lastAvailable) {
            onDaySelected([], {date: vm.lastAvailable, mdp: {selected: false}});
            updateAxisTitle();
        }

        //***************************************************************
        function parCurveSelected() {
            vm.curveType = "par";
            updateCurveLimits();
            updateAxisTitle();
        }

        function zeroCurveSelected() {
            vm.curveType = "zero";
            updateCurveLimits();
            updateAxisTitle();
        }

        function onCurveRangeChanged() {
            vm.curveRangeLimit = Math.max(2, vm.curveRangeModel);
        }

        function onCurveRangeSaved() {
            vm.chartMaxTenor = vm.curveRangeLimit * 365 + Math.ceil(vm.curveRangeLimit / 4);
        }

        function onExcelExportClicked() {
            var excelFormat = 'xlsx';
            var dateList = vm.selectedDates.map(function (date) {
                return DateUtils.convertLocalDateToServer(date.toDate());
            });
            if (!dateList || dateList.length === 0) {
                return;
            }
            DataExportOpen('excel')
                .query({
                    date: dateList,
                    format: excelFormat,
                    languageKey: vm.langKey,
                    rating: "bb"
                })
                .$promise
                .then(function (response) {
                    var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                    var fileName = "exported-data-bb." + excelFormat;
                    if (fileNameIndex > -1) {
                        fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                    }
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName.substr(1, fileName.length - 2));
                });
        }

        function onDaySelected(event, dateObj) {
            var unselected = dateObj.mdp.selected;
            var dateString = DateUtils.convertLocalDateToServer(dateObj.date.toDate());
            if (unselected) {
                var curveIndex = vm.chartData.lineLegends
                    .findIndex(function (elem) {
                        return elem === dateString;
                    });
                if (curveIndex > -1) {
                    //remove line from the chart
                    vm.chartData.lineSeries.par.splice(curveIndex, 1);
                    vm.chartData.lineSeries.zero.splice(curveIndex, 1);
                    //remove line from the chart legend
                    vm.chartData.lineLegends.splice(curveIndex, 1);
                    updateCurveLimits();
                }
            } else {
                //load curves data
                getResultsForDate(dateString)
                    .then(function (res) {
                        var promises = [];
                        promises.push(ParCorporateCurvePointOpen.query({ycId: res.id}).$promise);
                        promises.push(CorporateCurvePointOpen.query({ycId: res.id}).$promise);
                        $q.all(promises)
                            .then(function (resParAndZero) {
                                var resPar = resParAndZero[0];
                                var resZero = resParAndZero[1];
                                //add par line to the chart data
                                vm.chartData.lineSeries.par.push(resPar);
                                //add zero line to the chart data
                                vm.chartData.lineSeries.zero.push(resZero);
                                vm.chartData.lineLegends.push(dateString);
                                updateCurveLimits();
                            });
                    });
            }
        }

        function getResultsForDate(dateString) {
            return CorporateCurveResultsOpen.get({date: dateString, rating: "bb"}).$promise;
        }

        function clearDates() {
            vm.chartData = {
                chartId: "yc_chart",
                lineSeries: {par: [], zero: []},
                lineLegends: []
            };
            vm.selectedDates = [];
        }

        function updateAxisTitle() {
            const compStr = (vm.curveType === 'par')
                ? 'semi-annual coupon / simple interest'
                : 'continuously compounded';
            const compStrKa = (vm.curveType === 'par')
                ? 'წელიწადში ორჯერადი კუპონი / მარტივი განაკვეთი'
                : 'ნაზარდი საპროცენტო განაკვეთი';
            vm.yAxisTitle = (vm.langKey === 'ka')
                ? 'შემოსავლიანობა (%, ' + compStrKa + ')'
                : 'Yield (%, ' + compStr + ')';
        }

        function updateCurveLimits() {
            var minValue = Number.MAX_VALUE;
            var maxValue = Number.MIN_VALUE;
            const lineData = vm.chartData.lineSeries[vm.curveType];
            for (var ix = 0; ix < lineData.length; ix++) {
                if (angular.isDefined(lineData[ix])) {
                    for (var jx = 0; jx < lineData[ix].length; jx++) {
                        var xNum = parseFloat(lineData[ix][jx].deltaPeriods);
                        minValue = Math.min(minValue, xNum);
                        maxValue = Math.max(maxValue, xNum);
                    }
                }
            }
            vm.chartMinTenor = minValue;
            vm.chartMaxTenor = (vm.chartMaxTenor === null)
                ? maxValue
                : Math.min(maxValue, vm.chartMaxTenor);
            vm.minSlider = Math.max(2, Math.round(minValue / 365));
            vm.maxSlider = Math.round(maxValue / 365);
            vm.curveRangeLimit = Math.round(vm.chartMaxTenor / 365);
            vm.curveRangeModel = Math.round(vm.chartMaxTenor / 365);
        }
    }
})();
